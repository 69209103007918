import Vue from "vue";
import GetAccessForm from "@/components/get-access-form/get-access-form.vue";
import YouTube from "@/components/youtube/youtube.vue";
import VueCookie from "vue-cookie";
import VModal from "vue-js-modal";
import axios from "axios";

Vue.use(VueCookie);
Vue.use(VModal);

export default {
  name: "App",
  components: {
    GetAccessForm,
    YouTube
  },
  data: () => {
    return {
      isLoggedIn: false,
      isCompanyVideoVisible: false,
      marketingTags: {
        utm_source: "",
        utm_medium: "",
        utm_campaign: "",
        partner_id: ""
      },
      mainTitle: "Reinforce:<br>делегируй рутину!",
      introDescription:
        "<p>Мы&nbsp;решаем типовые задачи бизнеса <br>в&nbsp;маркетинге и&nbsp;digital</p>",
      aboutText:
        "<p>Reinforce&nbsp;&mdash; это платформа для передачи рутинной работы внешним исполнителям. Вы&nbsp;ставите задачи, а&nbsp;проверенные специалисты платформы выполняют&nbsp;их. Не&nbsp;нужно тратить время на&nbsp;найм, тесты и&nbsp;адаптацию&nbsp;&mdash; мы&nbsp;уже сделали это за&nbsp;вас.</p>",
      workWaysTitle:
        "Быстро и&nbsp;качественно<br> справимся с&nbsp;задачами по&nbsp;направлениям",
      profitability: [
        "Вы&nbsp;получите готовые решения и&nbsp;скинете с&nbsp;себя рутинные процессы",
        "Решите проблему нехватки кадров",
        "Оптимизируете затраты на&nbsp;сотрудников, не&nbsp;теряя в&nbsp;качестве оказания услуг",
        "Обеспечите бесперебойный рабочий процесс",
        "Не&nbsp;будете отказываться от&nbsp;новых проектов из-за перегруза штатных специалистов"
      ],
      helpful: [
        {
          iconClassName: "work-ways__icon--agency",
          title: "Рекламные агентства"
        },
        {
          iconClassName: "work-ways__icon--digital",
          title: "Digital-маркетологи"
        },
        {
          iconClassName: "work-ways__icon--studio",
          title: "Веб-студии и&nbsp;разработчики"
        }
      ],
      workWaysList: [
        {
          title: "Seo",
          list: [
            "аудит ссылочной массы сайта и&nbsp;наличия санкций со&nbsp;стороны Яндекса и&nbsp;Google",
            "подготовка&nbsp;ТЗ на&nbsp;смену структуры сайта",
            "техническая оптимизация скорости загрузки",
            "<a data-link='reg_or_lk'>больше задач в&nbsp;личном кабинете</a>"
          ]
        },
        {
          title: "Веб-аналитика",
          list: [
            "анализ конкурентов по&nbsp;торговым предложениям и&nbsp;УТП, трафику",
            "анализ и&nbsp;тегирование звонков",
            "подготовка аудита юзабилити и&nbsp;составление user story",
            "<a data-link='reg_or_lk'>больше задач в&nbsp;личном кабинете</a>"
          ]
        },
        {
          title: "Контекстная реклама",
          list: [
            "подбор и&nbsp;группировка семантического ядра",
            "минусация слов",
            "запуск рекламных кампаний и&nbsp;стартовая аналитика",
            "<a data-link='reg_or_lk'>больше задач в&nbsp;личном кабинете</a>"
          ]
        },
        {
          title: "Веб-разработка",
          list: [
            "доработка сайта на&nbsp;Битриксе (и&nbsp;других CMS)",
            "техническая поддержка сайта на&nbsp;Wordpress (и&nbsp;других CMS)",
            "задача для веб-разработчика на&nbsp;OpenCart (и&nbsp;других CMS)",
            "<a data-link='reg_or_lk'>больше задач в&nbsp;личном кабинете</a>"
          ]
        }
      ],
      advantages: {
        mainTitle: "Преимущества работы с&nbsp;Reinforce",
        items: [
          {
            title: "Экономия",
            text:
              "<p>На&nbsp;30% дешевле, чем найм штатных специалистов. <span class='advantages__note'>(по&nbsp;расчетам Reinforce на&nbsp;примере задач по&nbsp;разработке и&nbsp;SEO)</span></p>"
          },
          {
            title: "Гибкость",
            text:
              "<p>Ставьте задачи, когда это нужно: более 200 квалифицированных специалистов готовы к&nbsp;работе. Без долгих поисков, тестов и&nbsp;найма.</p>"
          },
          {
            title: "Качество",
            text:
              "<p>Исполнители работают по&nbsp;стандартам платформы&nbsp;&mdash; это исключает риск критических ошибок и&nbsp;срыва дедлайнов.</p>"
          },
          {
            title: "Гарантия результата",
            text:
              "<p>Мы&nbsp;не&nbsp;знаем слова &laquo;форс-мажор&raquo;: если исполнитель не&nbsp;может сдать задачу вовремя, платформа самостоятельно подберет замену и&nbsp;сдаст решение точно в&nbsp;срок.</p>"
          },
          {
            title: "Заботливая поддержка",
            text:
              "<p>Есть вопросы? Консультанты платформы помогут создать задачу и&nbsp;справиться со&nbsp;сложностями.</p>"
          }
        ]
      },
      faq: [
        {
          title: "Какие задачи можно поручить платформе?",
          isVisible: false,
          text:
            "<p>Платформа Reinforce помогает найти квалифицированных исполнителей для простых рутинных задач&nbsp;&mdash; таких, которые занимают время ваших высокооплачиваемых штатных специалистов. Например, решить отдельную задачу&nbsp;&mdash; собрать семантическое ядро, протегировать звонки. Или комплексную&nbsp;&mdash; запустить рекламную кампанию, провести аудит или исправить ошибки в&nbsp;SEO. Полный список задач можно посмотреть в&nbsp;<a data-link='reg_or_lk'>личном кабинете</a>.</p>"
        },
        {
          title: "Как поставить задачу?",
          isVisible: false,
          text:
            "<p>Чтобы поставить задачу на&nbsp;платформе Reinforce, нужно получить доступ в&nbsp;<a data-link='reg_or_lk'>личный кабинет</a> и&nbsp;пополнить баланс. Для этого зарегистрируйтесь на&nbsp;сайте, запросите в&nbsp;<a data-link='reg_or_lk'>личном кабинете</a> счет и&nbsp;оплатите его. Выбирайте задачи из&nbsp;представленного на&nbsp;платформе списка, указывайте детали и&nbsp;ставьте задачу. Платформа сама направит ее&nbsp;специалисту, а&nbsp;потом выдаст результат.</p>"
        },
        {
          title: "А если на платформе нет нужной задачи?",
          isVisible: false,
          text:
            "<p>Напишите об&nbsp;этом в&nbsp;форме под списком готовых задач в&nbsp;личном кабинете. Мы&nbsp;постараемся помочь.</p>"
        },
        {
          title: "Кто работает над моей задачей?",
          isVisible: false,
          text:
            "<p>Над задачей работают профильные специалисты в&nbsp;области маркетинга, SEO, контекстной рекламы, веб-аналитики и&nbsp;веб-разработки. В&nbsp;Reinforce есть рейтинг, который позволяет передавать задачу тем, кто уже имеет опыт аналогичной работы, выполняет задачи качественно, ответственно и&nbsp;в&nbsp;срок. По&nbsp;умолчанию платформа отправляет заказ свободному исполнителю с&nbsp;наивысшим рейтингом.</p>"
        },
        {
          title: "Как исполнители обеспечивают всегда качественный результат?",
          isVisible: false,
          text:
            "<p>Все специалисты проходят обучение и&nbsp;аттестацию на&nbsp;знание стандартов Reinforce. Стандарт платформы содержит всю необходимую информацию для обеих сторон: формат брифа, ожидаемый результат, подробные инструкции вы&nbsp;всегда можете дополнить стандарты своими требованиями: по&nbsp;вашему&nbsp;ТЗ и&nbsp;примеру результата мы&nbsp;составим новую инструкцию для исполнителя.</p>"
        },
        {
          title: "Нужно ли контролировать исполнителя?",
          isVisible: false,
          text:
            "<p>Нет, вам не&nbsp;придется тратить на&nbsp;это время. Исполнителей контролируют кураторы платформы. Они позаботятся, чтобы ваши задачи были выполнены качественно и&nbsp;в&nbsp;срок. Именно кураторы подбирают квалифицированных специалистов, проверяют их&nbsp;компетенции и&nbsp;допускают к&nbsp;работе. Также они следят за&nbsp;сроками и&nbsp;могут заменить исполнителя, если это потребуется.</p>"
        },
        {
          title: "Чем Reinforce отличается от бирж фриланса?",
          isVisible: false,
          text:
            "<p>На&nbsp;любой фриланс-бирже вы&nbsp;будете вынуждены потратить несколько часов на&nbsp;поиск и&nbsp;выбор исполнителя, тестовые задания и&nbsp;банальные переговоры. В&nbsp;Reinforce вы&nbsp;общаетесь с&nbsp;платформой, где все предварительные действия мы&nbsp;уже взяли на&nbsp;себя, сэкономив ваше драгоценное время: выбрали специалистов, проверили их&nbsp;компетенции, договорились обо всем.</p>"
        },
        {
          title: "А если мне не понравится результат?",
          isVisible: false,
          text:
            "<p>Мы&nbsp;стремимся выполнять ваши задачи с&nbsp;первого раза. Если вас не&nbsp;устраивает результат, вы&nbsp;можете вернуть задачу на&nbsp;доработку. Куратор проверит замечания и&nbsp;отправит их&nbsp;исполнителю. В&nbsp;крайнем случае&nbsp;&mdash; передаст задачу другому специалисту.</p>"
        },
        {
          title: "Доступы к аналитике и моему сайту будут в безопасности?",
          isVisible: false,
          text:
            "<p>Да, за&nbsp;это можно не&nbsp;переживать. Исполнитель имеет ограниченный по&nbsp;времени доступ к&nbsp;личным кабинетам вашего проекта. Все пароли хранятся под &laquo;капотом&raquo; платформы и&nbsp;не&nbsp;передаются в&nbsp;открытом виде. Это возможно благодаря технологии проксирования доступа, которую использует Reinforce.</p>"
        },
        {
          title:
            "Можно ли поставить задачи вашим исполнителям на ночь или на выходные?",
          isVisible: false,
          text:
            "<p>Да, мы&nbsp;принимаем задачи 24&nbsp;часа в&nbsp;сутки, 7&nbsp;дней в&nbsp;неделю. Можете на&nbsp;нас положиться!</p>"
        }
      ]
    };
  },
  created() {
    axios
      .get("https://reinforce.team/local/api/oauth/is_auth.php")
      .then(response => (this.isLoggedIn = response.data))
      .catch(() => (this.isLoggedIn = false));

    this.marketingTags.partner_id = this.$cookie.get("partner_id") || "";

    let urlString = window.location.toString().split("?");
    if (urlString.length > 1) {
      let pairs = urlString[1].split("&");
      for (let i in pairs) {
        let keyval = pairs[i].split("=");
        if (keyval[0] === "utm_source") {
          this.marketingTags.utm_source = keyval[1];
        }
        if (keyval[0] === "utm_medium") {
          this.marketingTags.utm_medium = keyval[1];
        }
        if (keyval[0] === "utm_campaign") {
          this.marketingTags.utm_campaign = keyval[1];
        }
        if (keyval[0] === "partner_id") {
          this.marketingTags.partner_id = keyval[1];
          this.$cookie.set("partner_id", this.marketingTags.partner_id, {
            expires: "1Y"
          });
        }
      }
    }
  },
  mounted() {
    this.addGoogleTagManagerScript();

    this.$nextTick(() => {
      let elements = document.querySelectorAll("[data-link=reg_or_lk]");
      elements.forEach(el => {
        el.addEventListener("click", () => {
          document.location = "https://reinforce.team/oanp/desktop/list.php";
        });
      });
    });
  },
  methods: {
    toggleFaqItem(index) {
      this.faq.forEach((item, num) => {
        if (index !== num) {
          item.isVisible = false;
        }
      });
      this.faq[index].isVisible = !this.faq[index].isVisible;
    },

    addGoogleTagManagerScript() {
      let gtmScript = document.createElement("script");
      gtmScript.type = "text/javascript";
      let code =
        "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\n" +
        "new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\n" +
        "j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n" +
        "'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n" +
        "})(window,document,'script','dataLayer','GTM-WZK4NGD');";
      try {
        gtmScript.appendChild(document.createTextNode(code));
        document.head.appendChild(gtmScript);
      } catch (e) {
        gtmScript.text = code;
        document.head.appendChild(gtmScript);
      }
    }
  }
};
