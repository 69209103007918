import { mask } from "vue-the-mask";
import axios from "axios";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, email, min } from "vee-validate/dist/rules";

extend("required", required);
extend("email", email);
extend("min", min);

export default {
  name: "GetAccessForm",
  directives: { mask },
  components: {
    ValidationObserver,
    ValidationProvider
  },
  props: {
    marketingTags: {
      utm_source: "",
      utm_medium: "",
      utm_campaign: ""
    }
  },
  data: () => {
    return {
      queryUrl:
        "https://reinforce.team/outside/landing/ajax/save_form_data.php",
      // queryUrl: "https://remote.local/outside/landing/ajax/save_form_data.php",
      isPasswordVisible: false,
      isFormSentSuccess: false,
      sendingInProgress: false,
      formData: {
        email: "",
        password: "",
        name: "",
        phone: "",
        company: "",
        utm_source: "",
        utm_medium: "",
        utm_campaign: "",
        partner_id: ""
      }
    };
  },
  mounted() {
    this.$refs.email.focus();
    this.formData.utm_source = this.marketingTags.utm_source;
    this.formData.utm_medium = this.marketingTags.utm_medium;
    this.formData.utm_campaign = this.marketingTags.utm_campaign;
    this.formData.partner_id = this.marketingTags.partner_id;
  },
  methods: {
    sendForm() {
      this.sendingInProgress = true;

      let collectedData = new FormData();

      Object.keys(this.formData).forEach(key => {
        collectedData.append(key, this.formData[key]);
      });

      axios
        .post(this.queryUrl, collectedData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          if (((response || {}).data || {}).status === "ok") {
            this.isFormSentSuccess = true;
          } else if (((response || {}).data || {}).message.length) {
            alert(response.data.message);
          }
          this.sendingInProgress = false;
        })
        .catch(error => {
          //eslint-disable-next-line no-console
          console.error("error", error);
          this.sendingInProgress = false;
          alert("Произошла ошибка, попробуйте позже");
        });

      if (typeof window.ym != "undefined") {
        // eslint-disable-next-line no-undef
        ym(53781976, "reachGoal", "accessRequest");
      }
    }
  }
};
