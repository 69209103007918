var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"get-access-form"},[(!_vm.isFormSentSuccess)?[_c('h1',{staticClass:"get-access-form__title"},[_vm._v("Доступ на платформу")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('form',{staticClass:"form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.sendForm)}}},[_c('label',{staticClass:"form__item"},[_c('span',{staticClass:"form__label-text form__label-text--required"},[_vm._v(" Электронная почта ")]),_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.email),expression:"formData.email"}],ref:"email",staticClass:"form__text-input",class:{ 'form__text-input--error': errors[0] },attrs:{"type":"text","placeholder":""},domProps:{"value":(_vm.formData.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "email", $event.target.value)}}})]}}],null,true)})],1),_c('label',{staticClass:"form__item"},[_c('span',{staticClass:"form__label-text form__label-text--required"},[_vm._v(" Пароль ")]),_c('ValidationProvider',{attrs:{"rules":"required|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form__input-wrapper"},[((_vm.isPasswordVisible ? 'text' : 'password')==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.password),expression:"formData.password"}],staticClass:"form__text-input",class:{ 'form__text-input--error': errors[0] },attrs:{"placeholder":"Не менее 6 символов","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.formData.password)?_vm._i(_vm.formData.password,null)>-1:(_vm.formData.password)},on:{"change":function($event){var $$a=_vm.formData.password,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.formData, "password", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.formData, "password", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.formData, "password", $$c)}}}}):((_vm.isPasswordVisible ? 'text' : 'password')==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.password),expression:"formData.password"}],staticClass:"form__text-input",class:{ 'form__text-input--error': errors[0] },attrs:{"placeholder":"Не менее 6 символов","type":"radio"},domProps:{"checked":_vm._q(_vm.formData.password,null)},on:{"change":function($event){return _vm.$set(_vm.formData, "password", null)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.password),expression:"formData.password"}],staticClass:"form__text-input",class:{ 'form__text-input--error': errors[0] },attrs:{"placeholder":"Не менее 6 символов","type":_vm.isPasswordVisible ? 'text' : 'password'},domProps:{"value":(_vm.formData.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "password", $event.target.value)}}}),_c('button',{class:[
                  'form__input-eye',
                  { 'form__input-eye--visible': _vm.isPasswordVisible }
                ],attrs:{"type":"button","tabindex":"-1"},on:{"click":function($event){_vm.isPasswordVisible = !_vm.isPasswordVisible}}})])]}}],null,true)})],1),_c('label',{staticClass:"form__item"},[_c('span',{staticClass:"form__label-text form__label-text--required"},[_vm._v("Имя")]),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.name),expression:"formData.name"}],staticClass:"form__text-input",class:{ 'form__text-input--error': errors[0] },attrs:{"type":"text","placeholder":""},domProps:{"value":(_vm.formData.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "name", $event.target.value)}}})]}}],null,true)})],1),_c('label',{staticClass:"form__item"},[_c('span',{staticClass:"form__label-text"},[_vm._v("Компания")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.company),expression:"formData.company"}],staticClass:"form__text-input",attrs:{"type":"text","placeholder":""},domProps:{"value":(_vm.formData.company)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "company", $event.target.value)}}})]),_c('label',{staticClass:"form__item form__item--margin-mini"},[_c('span',{staticClass:"form__label-text form__label-text--required"},[_vm._v(" Номер мобильного телефона ")]),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.phone),expression:"formData.phone"},{name:"mask",rawName:"v-mask",value:('+#############################'),expression:"'+#############################'"}],staticClass:"form__text-input",class:{ 'form__text-input--error': errors[0] },attrs:{"type":"text","placeholder":""},domProps:{"value":(_vm.formData.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "phone", $event.target.value)}}})]}}],null,true)})],1),_c('span',{staticClass:"form__footnote"},[_vm._v(" Позвоним и поможем сориентироваться на старте. ")]),_c('button',{staticClass:"button button--large button--full button--primary button--submit",attrs:{"type":"submit","disabled":invalid || _vm.sendingInProgress}},[_vm._v(" Отправить ")]),_c('span',{staticClass:"form__footnote form__footnote--center"},[_vm._v(" Нажимая на кнопку, "),_c('br'),_vm._v(" вы соглашаетесь с "),_c('a',{attrs:{"href":"landing/legal/rules.pdf","target":"_blank"}},[_vm._v("условиями")]),_vm._v(". ")])])]}}],null,false,4062701058)})]:_vm._e(),(_vm.isFormSentSuccess)?[_c('h1',{staticClass:"get-access-form__title get-access-form__title--mini-margin-bottom"},[_vm._v(" Добро пожаловать! ")]),_c('div',{staticClass:"get-access-form__text"},[_c('p',[_vm._v(" Перейдите на страницу входа и используйте"),_c('br'),_vm._v(" электронную почту "+_vm._s(_vm.formData.email)),_c('br'),_vm._v(" и указанный ранее пароль для доступа"),_c('br'),_vm._v(" на платформу. ")]),_c('a',{staticClass:"button button--primary button--login-from-success-reg",attrs:{"href":"/oanp/desktop/list.php"}},[_vm._v(" Вход на платформу ")])])]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }